.page {
  @apply .relative;

  &__zone {
    z-index: 2;

    &.has-image {
      &:before {
        @apply .absolute;
        content: '';
        top: 0;
        right: 0;
        left: 0;
        height: calc-rem(56);
        background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0));
        opacity: 0;
        transition: .3s ease-in-out opacity;
      }

      &:hover:before {
        opacity: .3;
      }
    }
  }

  &__drag {
    @apply .uppercase .flex .flex-col .justify-center .items-center .h-full .text-center;
    padding: 0 .5rem;
    color: $color__grey;
    font-family: $font-title;

    svg {
      margin-bottom: .5rem;
      fill: $color__grey;
    }
  }

  &__crop {
    @apply .absolute .text-center .flex-col .justify-end .hidden;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem calc-rem(18);
    color: $color__white;
    font-size: calc-rem(18);
    font-family: $font-title;
    background-color: rgba(255, 142, 0, 0.7);
    pointer-events: none;

    &:focus {
      outline: none;
    }

    svg {
      margin-bottom: .5rem;
      fill: $color__white;
    }
  }

  &__txt-left {
    @apply .absolute;
    top: 66.8%;
    left: 20%;
    width: 28%;

    &--double {
      left: 14.2%;
      width: 16%;
    }
  }

  &__name {
    margin-bottom: .5rem;
    font-size: calc-rem(35);
    font-family: $font-title2;
    letter-spacing: -.5px;
  }

  &__infos {
    font-size: 1rem;
    font-family: $font-title2;
    line-height: 1;
  }

  &__txt-right {
    @apply .absolute;
    top: 67.5%;
    right: 23%;
    width: 28%;
    font-size: calc-rem(10);
    font-family: $font-chapo;
    line-height: 1.1;

    &--double {
      right: auto;
      left: 30.6%;
      width: 16%;
    }

    p:last-child {
      font-style: italic;
    }
  }

  .has-crop-error + .page__crop {
    display: flex;
    pointer-events: auto;
  }

  .is-small {
    .page__crop {
      padding: .75rem;
      font-size: calc-rem(14);

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .page__drag {
      font-size: .75rem;
    }
  }
}

// template 1A
.page-1a {
  &__zone {
    &--1 {
      top: 17.3%;
      left: 18.3%;
      width: 29.5%;
      height: 15.7%;
    }

    &--2 {
      top: 33.5%;
      left: 18.3%;
      width: 29.5%;
      height: 32.5%;
    }

    &--3 {
      top: 17.3%;
      left: 48.8%;
      width: 29.6%;
      height: 32.5%;
    }

    &--4 {
      top: 50.4%;
      left: 48.8%;
      width: 29.6%;
      height: 15.6%;
    }
  }
}

// template 1B
.page-1b {
  &__zone {
    &--1 {
      top: 17.3%;
      left: 18.3%;
      width: 29.5%;
      height: 32.4%;
    }

    &--2 {
      top: 50.4%;
      left: 18.3%;
      width: 14.3%;
      height: 15.4%;
    }

    &--3 {
      top: 50.4%;
      left: 33.5%;
      width: 14.3%;
      height: 15.4%;
    }

    &--4 {
      top: 17.4%;
      left: 48.8%;
      width: 14.3%;
      height: 15.5%;
    }

    &--5 {
      top: 17.4%;
      left: 64.1%;
      width: 14.3%;
      height: 15.5%;
    }

    &--6 {
      top: 33.5%;
      left: 48.8%;
      width: 29.6%;
      height: 32.4%;
    }
  }
}

// template 1C
.page-1c {
  &__zone {
    &--1,
    &--2,
    &--3,
    &--4 {
      top: 17.3%;
      width: 14.3%;
      height: 15.6%;
    }

    &--1 {
      left: 18.3%;
    }

    &--2 {
      left: 33.5%;
    }

    &--3 {
      left: 48.8%;
    }

    &--4 {
      left: 64.1%;
    }

    &--5 {
      top: 33.6%;
      left: 18.3%;
      width: 29.5%;
      height: 32.2%;
    }

    &--6,
    &--7 {
      top: 33.6%;
      left: 48.8%;
      width: 29.6%;
      height: 15.8%;
    }

    &--7 {
      top: 50%;
    }
  }
}

// template 1D
.page-1d {
  .page__zone {
    width: 14.3%;
  }

  &__zone {
    &--1 {
      left: 18.3%;
      width: 29.5%!important;
      height: 32.4%!important;
    }

    &--1,
    &--4,
    &--5 {
      top: 17.3%;
    }

    &--4,
    &--5,
    &--6,
    &--7 {
      height: 15.9%;
    }

    &--2,
    &--3,
    &--8,
    &--9 {
      top: 50.4%;
      height: 15.4%;
    }

    &--2 {
      left: 18.3%;
    }

    &--3 {
      left: 33.5%;
    }

    &--4,
    &--6,
    &--8 {
      left: 48.8%;
    }

    &--5,
    &--7,
    &--9 {
      left: 64.1%;
    }

    &--6,
    &--7 {
      top: 33.8%;
    }
  }
}

// template 2A
.page-2a {
  .page__zone {
    width: 17%;
  }

  &__zone {
    &--1,
    &--3,
    &--8 {
      top: 17.1%;
    }

    &--5,
    &--8 {
      top: 17.3%;
    }

    &--1,
    &--4,
    &--6,
    &--7,
    &--8,
    &--9 {
      height: 15.8%;
    }

    &--1,
    &--2 {
      left: 13.2%;
    }

    &--3,
    &--4 {
      left: 30.7%;
    }

    &--4 {
      top: 50.2%;
    }

    &--6 {
      top: 50.5%;
    }

    &--7 {
      top: 66.9%;
    }

    &--6,
    &--7 {
      height: 15.6%;
    }

    &--2,
    &--3,
    &--5 {
      height: 32.5%;
    }

    &--2 {
      top: 33.5%;
    }

    &--5,
    &--6,
    &--7 {
      left: 52.1%;
      width: 17.2%!important;
    }

    &--8,
    &--9,
    &--10 {
      left: 70%;
      width: 17.4%!important;
    }

    &--8 {
      height: 16%;
    }

    &--9 {
      top: 34%;
    }

    &--10 {
      top: 50.5%;
      height: 32%;
    }
  }
}

// template 2B
.page-2b {
  .is-small .page__crop {
    padding: .25rem;
  }

  &__zone {
    &--1,
    &--6 {
      width: 17%;
      height: 32.4%;
    }

    &--2,
    &--3,
    &--4,
    &--5 {
      width: 8.2%;
      height: 15.5%;
    }

    &--1,
    &--2 {
      left: 13.2%;
    }

    &--1,
    &--4,
    &--5 {
      top: 17.2%;
    }

    &--2,
    &--3 {
      top: 50.3%;
    }

    &--3 {
      left: 22%;
    }

    &--4,
    &--5 {
      height: 15.6%;
    }

    &--4,
    &--6 {
      left: 30.7%;
    }

    &--5 {
      left: 39.4%;
    }

    &--6 {
      top: 33.5%;
    }

    &--7,
    &--12 {
      top: 17.3%;
      height: 32.5%;
    }

    &--7 {
      width: 17.3%;
    }

    &--12,
    &--13 {
      left: 70%;
      width: 17.5%;
    }

    &--8,
    &--9,
    &--10,
    &--11 {
      width: 8.3%;
      height: 15.5%;
    }

    &--8,
    &--9,
    &--13 {
      top: 50.6%;
    }

    &--7,
    &--8,
    &--10 {
      left: 52.1%;
    }

    &--9,
    &--11 {
      left: 61.1%;
    }

    &--10,
    &--11 {
      top: 67%;
    }

    &--13 {
      height: 31.9%;
    }
  }
}