// TEXTUAL INPUTS (text, email, phone, ...)
.text-input {
  @apply .w-full;
  height: calc-rem(56);
  padding: .75rem 1rem;
  color: $color__dark-grey;
  font-size: calc-rem(18);
  line-height: 1.78;
  background-color: transparent;
  border: 2px solid $color__light-grey;
  border-radius: .25rem;
  transition: border-color 150ms $cubic-easing;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
    -webkit-text-fill-color: $color__dark-grey;
  }

  &::placeholder {
    color: $color__white;
  }

  &:focus,
  &:-webkit-autofill,
  &:not(:placeholder-shown) {
    border-color: $color__black;
    outline: none;

    + .form__label {
      z-index: 1;
      color: $color__black;
      font-weight: 600;
      transform: translateY(-28px) scale(.77);

      &:before {
        @apply .absolute;
        content: '';
        top: 0;
        right: -.5rem;
        bottom: 0;
        left: -.5rem;
        z-index: -1;
        background-color: $color__white;
      }
    }
  }

  .has-error & {
    border-color: $color__red;
  }
}

// TEXTAREA
.textarea {
  @extend .text-input;

  height: calc-rem(320);
  font-family: $font-body;
  line-height: 1.33;
  resize: none;

  &::placeholder {
    color: $color__dark-grey;
  }

  &-count {
    @apply .absolute;
    right: .5rem;
    bottom: 1.25rem;
    font-weight: 300;
    font-size: calc-rem(18);
    font-family: $font-title;
  }

  &-wrap {
    &.is-disabled {
      display: none;
    }

    &.has-error {
      .textarea-count {
        bottom: 2.75rem;
      }
    }
  }
}