.home {
  @apply .flex .w-full;
  min-height: 100%;

  &__side {
    @apply .relative;
    width: 50%;
  }

  &__content {
    padding: 5rem 5rem 5rem 10rem;

    &-inner {
      max-width: 31rem;
    }
  }

  &__illu {
    background: url('/assets/front/img/bg-hp.jpg');
    background-size: cover;
  }

  &__welcome {
    @apply .absolute;
    top: 50%;
    right: 0;
    max-height: 85%;
    transform: translate(35%, -50%);
  }
}