.content {
  @apply .w-full .relative;

  &--full {
    padding: calc-rem(56) 3rem 9.75rem;
    background-color: $color__bg-grey;

    @include responsive(xxl) {
      padding: calc-rem(56) 5rem 9.75rem;
    }
  }

  &--full2 {
    padding-bottom: 11.75rem!important;
  }

  &--full3 {
    padding-right: 0;
    padding-bottom: 3rem;
  }
}

.content-split {
  @apply .flex;

  &__left {
    flex-shrink: 0;
    width: calc-rem(540);
    padding: calc-rem(56) 5rem 4.5rem;
  }

  &__right {
    @apply .flex .items-start;
    width: calc(100% - 540px);
    background-color: $color__bg-grey;

    &--custom {
      background-color: $color__white;
    }
  }

  &__single {
    width: 100%;
    max-width: calc-rem(622);

    @include responsive(xl) {
      margin-left: calc-rem(28);
    }

    @media screen and (min-width: 1520px) {
      width: auto;
      max-width: 100%;
    }
  }

  &__double {
    width: 113%;
    max-width: calc-rem(734);
    margin-top: 8vh;
    margin-left: -6%;

    @include responsive(xxl) {
      margin-left: -2.5rem;
    }

    @media screen and (min-width: 1520px) {
      max-width: calc-rem(1565);
      margin-right: auto;
      margin-left: auto;
    }
  }
}