.login {
  @apply .relative;
  padding: 5rem 0;

  &:before,
  &:after {
    @apply .absolute;
    top: 0;
    content: '';
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-attachment: fixed;
    pointer-events: none;
  }

  &:before {
    left: 0;
    background-image: url('/assets/front/img/left.png');
  }

  &:after {
    right: 0;
    background-image: url('/assets/front/img/right.png');
    background-position: right top;
  }

  &__content {
    width: calc-rem(460);
    margin: 0 auto;
  }

  &__nav {
    margin: calc-rem(56) 0 calc-rem(60);
    font-weight: 300;
    font-size: 2rem;
    font-family: $font-title;
  }

  &__nav-link {
    @apply .block;
    color: $color__grey;
    line-height: .75;

    &.is-active {
      color: $color__black;
      font-weight: 400;
      border-bottom: 3px solid;
    }

    &:hover {
      color: $color__black;
    }
  }
}

.legals {
  h2 {
    margin-bottom: .75rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $color__black;
    }
  }
}