.sidebar {
  flex-shrink: 0;
  width: calc-rem(240);
  color: rgba($color__white, .4);
  background-color: $color__black;

  &__action {
    display: none;
  }

  &__step {
    @apply .flex .relative;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid rgba($color__white, .4);

    &:after {
      @apply .absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $color__white;
      opacity: 0;
      pointer-events: none;
    }


    &.is-completed {
      &:after {
        opacity: .2;
      }

      .sidebar__action {
        display: block;
      }
    }

    &.is-disabled {
      .title-2 {
        opacity: .3;
      }

      .sidebar__step-subtitle {
        opacity: .5;
      }
    }

    &.is-active {
      &:after {
        opacity: .3;
      }
    }

    &.is-completed,
    &.is-active {
      color: $color__white;
    }

    .icon {
      fill: #00ff13;
    }

    &-subtitle {
      color: rgba($color__white, .4);
    }
  }
}