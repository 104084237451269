.image-thumbnail {
  @apply .flex .flex-col .items-center .justify-center .relative;
  width: calc-rem(74);
  height: calc-rem(93);
  margin-bottom: .5rem;

  &:not(:nth-child(4n)) {
    margin-right: .5rem;
  }

  &__empty {
    display: none;
    font-family: $font-title;
    opacity: .5;

    .icon {
      margin-bottom: .5rem;
      opacity: .9;
    }
  }

  &.is-empty {
    border: 2px dashed rgba($color__black, .5);
    border-radius: 2px;
  }

  &.is-disabled {
    img {
      pointer-events: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color__black, .5);
      cursor: not-allowed;
    }
  }
  
  &__image {
    cursor: grab;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 3rem;
      background-image: linear-gradient(to bottom, $color__black, rgba($color__black, .0001));
      opacity: .4;
      pointer-events: none;
    }
  }

  &__delete {
    @apply .absolute;
    top: .25rem;
    right: .25rem;
    z-index: 1;
    fill: $color__white;

    &:focus {
      outline: none;
    }
  }
}

.page__zone {
  .image-thumbnail {
    &__delete {
      opacity: 0;
    }
  }

  &:hover {
    .image-thumbnail__delete {
      opacity: 1;
    }
  }
}

.is-empty {
  .image-thumbnail {
    &__empty {
      display: block;
    }

    &__image {
      display: none;
    }
  }
}

.images {
  &__input {
    @apply .absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
  }

  &__error {
    @apply .text-center;
    display: none;
    margin-top: .5rem;
    color: $color__red;

    &.is-visible {
      display: block;
    }
  }

  &-pages {
    max-width: calc(100vw - 628px);

    &--single {
      padding-right: calc-rem(60);
      padding-left: calc-rem(60);

      @include responsive(xl) {
        padding-left: calc-rem(116);
      }
    }

    &--double {
      max-width: calc-rem(1423);
      padding-top: calc-rem(70);
    }

    &__bg-single {
      max-width: 100%;
      margin: -18.5% 0 -3rem;
    }

    &__bg-double {
      width: 110%;
      margin: -18.5% 0 -1rem -5%;
    }
  }
}

.cropper-line {
  background: transparent!important;
}