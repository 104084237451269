.thanks {
  @apply .relative;

  &__content {
    @apply .text-center .relative;
    z-index: 3;
    width: calc-rem(634);
  }

  &__title {
    font-size: calc-rem(200);
    font-family: $font-title2;
  }

  &__more {
    @apply .absolute .text-center;
    right: 0;
    bottom: 2rem;
    left: 0;

    a {
      color: $color__gold;
      font-size: 1rem;

      &:after {
        bottom: .25rem;
      }
    }
  }
}