.choice {
  @apply .relative;
  
  &--single {
    width: 34.6%;

    @include responsive(xl) {
      width: calc-rem(360);
    }
  }

  &--single2 {
    width: 46%;

    @include responsive(xl) {
      width: calc-rem(480);
    }
  }

  &--double {
    width: 57.67%;

    @include responsive(xl) {
      width: calc-rem(600);
    }

    @include max-responsive(xl) {
      .choice__inner {
        padding-bottom: 2.75rem;
      }
    }
  }

  &--double2 {
    @apply .w-full;
    max-width: calc-rem(780);
    
    @include responsive(xl) {
      width: calc-rem(780);
    }
  }

  img {
    margin: 0 auto;
  }

  &__inner {
    padding: 1.25rem 0;
    border: 2px solid transparent;
    transition: .3s $easing__material border-color;

    &--2 {
      padding: calc-rem(30) 0 2.25rem;
    }
  }

  &__check {
    @apply .absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    opacity: 0;
    transition: .3s $easing__material opacity;
  }

  &__input {
    @apply .absolute .w-full .h-full;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;

    &:checked + .choice__inner {
      border-color: $color__black;

      .choice__check {
        opacity: 1;
      }
    }
  }

  &__infos {
    @apply .absolute;
    bottom: calc-rem(-10);
    left: 50%;
    padding: 0 1rem;
    white-space: nowrap;
    transform: translate(-50%, 50%);

    &:before {
      content: '';
      position: absolute;
      top: 1.25rem;
      right: 0;
      left: 0;
      z-index: -1;
      height: 1.5rem;
      background-color: $color__bg-grey;
    }
  }
}