.loader {
  @apply .absolute .flex .flex-col .items-center .justify-center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  color: $color__white;
  background-color: rgba($color__black, .5);
  visibility: hidden;
  opacity: 0;
  transition: .3s ease-in-out visibility, .3s ease-in-out opacity;

  &__inner {
    display: inline-block;
    width: 160px;
    height: 160px;

    &:after {
      content: " ";
      display: block;
      width: 128px;
      height: 128px;
      margin: 8px;
      border: 6px solid $color__white;
      border-color: $color__white transparent $color__white transparent;
      border-radius: 50%;
    }
  }
  
  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &.is-active {
    visibility: visible;
    opacity: 1;

    .loader__inner:after {
      animation: dual-ring 1.2s linear infinite;
    }
  }
}