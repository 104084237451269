.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  height: 100%;
  pointer-events: none;
}

.modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity .3s $easing__material;
  pointer-events: auto;

  &__action {
    @apply .absolute;
    right: 3rem;
    bottom: 1rem;
  }

  &__inner {
    @apply .flex .justify-center;
    position: relative;
    max-width: 90vw;
    max-height: 90%;
    padding: 3rem 3rem 5rem;
    overflow-y: auto;
    background-color: $color__white;
  }

  &__close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    transition: opacity 0.3s $easing__out--cubic;

    &:hover {
      opacity: 0.6;
    }
  }

  &__close-icon {
    width: 2rem;
    height: 2rem;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}