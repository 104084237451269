.images-sidebar {
  @apply .relative;
  z-index: 6;
  flex-shrink: 0;
  width: calc-rem(320);

  &__infos {
    padding: 1rem;
    border: 1px solid $color__black;
  }
}