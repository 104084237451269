// CHECKBOX
.custom-checkbox {
  position: relative;
  display: inline-block;

  &__label {
    position: relative;
    display: block;
    padding-left: calc-rem(26);
    color: $color__dark-grey;
    font-size: 1rem;

    a {
      @apply .inline-block;
      font-size: 1rem;
    }
  
    &:before,
    &:after {
      position: absolute;
      content: '';
    }
  
    &:before {
      top: .25rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      border: 1px solid $color__light-grey;
      border-radius: 2px;
      transition: .3s border linear;
    }
  
    &:after {
      top:  .5rem;
      left: 4px;
      width: .5rem;
      height: .5rem;
      background-color: $color__black;
      opacity: 0;
      transition: .3s opacity $easing__material;
  
      input:checked + & {
        opacity: 1;
      }
    }
  
    .has-error &:before {
      border-color: $color__red;
    }
  }

  input {
    position: absolute;
    z-index: 5;
    width: 22px;
    height: 22px;
    cursor: pointer;
    opacity: 0;
  }

  .form-error {
    padding-left: 2.75rem;
  }
}

.switch {
  position: relative;
  display: inline-block;
  padding-left: 3rem;
  color: $color__dark-grey;
  font-size: calc-rem(18);

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__slider {
    position: absolute;
    top: 2px;
    left: 0;
    width: 2.5rem;
    height: 1.5rem;
    background-color: $color__light-grey;
    border-radius: .75rem;
    cursor: pointer;
    transition: .3s $easing__material background-color;

    &:before {
      position: absolute;
      bottom: 2px;
      left: 2px;
      content: '';
      width: 1.25rem;
      height: 1.25rem;
      background-color: $color__white;
      border-radius: 50%;
      transition: .3s transform $easing__material;
    }
  }
}

input:checked + .switch__slider {
  background-color: $color__black;

  &:before {
    transform: translateX(1rem);
  }
}