.form {
  &__row {
    @apply .relative;
    margin-bottom: 2rem;
  }

  &__label {
    @apply .absolute;
    top: 1rem;
    left: 1rem;
    display: block;
    color: $color__dark-grey;
    font-size: calc-rem(18);
    transform-origin: 0% 0%;
    transition: transform 150ms $cubic-easing, color 150ms $cubic-easing;
    pointer-events: none;

    &.is-required:after {
      @apply .absolute;
      content: '*';
      top: 0;
      right: -.5rem;
    }
  }

  &__zip {
    width: calc-rem(90);
  }

  &__field-error {
    margin-top: 4px;
    color: $color__red;
    font-size: calc-rem(14);
  }
}