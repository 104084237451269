// RADIOS
.custom-radio {
  @apply .relative;
  
  &__label {
    position: relative;
    padding-left: 2rem;
    color: $color__dark-grey;
    font-size: calc-rem(18);

    &:before,
    &:after {
      position: absolute;
      content: '';
    }

    &:before {
      top: calc-rem(5);
      left: .25rem;
      width: 1rem;
      height: 1rem;
      background-color: $color__black;
      border-radius: 50%;
      opacity: 0;
      transition: .3s opacity $easing__material;
    }

    &:after {
      top: 50%;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid $color__light-grey;
      border-radius: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  
  .form__field-error {
    @apply .absolute;
    bottom: -1.5rem;
    left: 0;
    white-space: nowrap;
  }

  input[type="radio"] {
    @include sr-only;

    width: 100%;
    height: 100%;
  }

  input:checked + &__label:before {
    opacity: 1;
  }
}